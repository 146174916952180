ul.nav-list{
    margin-bottom: 2000px;
    background-color: #A4A4A4;


}

tr td.oculto {
    white-space: nowrap
}

tr td.oculto a, tr td.oculto button {
    visibility: hidden;
    width: 25px;
    height: 25px;
}

tr:hover td.oculto a, tr:hover td.oculto button{
    visibility: visible;

    width: 25px;
    height: 25px;
}
.table-column-buttons{

}
.btn{
  visibility:visible !important;

}
